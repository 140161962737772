import debounce from "lodash/debounce";
import { useCallback, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useScreenWidth from "../../../../../../../hooks/useScreenwidth";
import { getProductsData } from "../../../../../../../services/productsService";
import { setLoading } from "../../../../../../../store/slices/loaderSlice";

import Search from "../../../../../../atoms/Search";
import style from "../../../../Investors/NewInvestorDatabase/Tabs/Company/styles.module.scss";

import NoContentCard from "../../../../../../molecules/NoContentCard";
import ContentSharingPhoneShimmer from "../../../../ContentSharing/Shimmer/ContentSharingPhones";
import ShimmerDatabase from "../../../Shimmer/shimmerDatabase";

import moment from "moment";
import ImageViewer from "../../../../../../atoms/ImageViewer";
import TypeDiv from "../../../../../../atoms/TypeDiv";
import Pagination from "../../../../../../molecules/Pagination";
import SaveComponent from "../../../../../../molecules/SaveComponent";

import { getData } from "../../../../../../../services";
import ResetBtn from "../../../../../../atoms/ResetBtn/ResetBtn";
import ArrowDown from "../../../../../../Icons/ArrowDown";
import SaveAll from "../../../../../../molecules/SaveComponent/SaveAll";
import MultiselectFilter from "../../../../../../organisms/MultiselectFilter";
import SortFunctionality from "../../../../../../organisms/SortFunctionality";
import CreatedInvestorFilter from "../../../../Investors/Filter/CreatedInvestorFilter";
import UpdateInvestorFilter from "../../../../Investors/Filter/UpdatedInvestorFilter";
import DireactDeal from "../../NewFilter/DirectDeal";
import Funds from "../../NewFilter/Funds";
import Location from "../../NewFilter/Location";

const Company = () => {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["t"]);
  const [showLoader, setShowLoader] = useState(false);
  const hasMounted = useRef(false);

  const [searchvalue, setSearchvalue] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sort_by, setSort_By] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const [aum, setAum] = useState({ min: null, max: null });
  const [fundingGoal, setFundingGoal] = useState({ min: null, max: null });
  // const [createdFilter, setCreatedFilter] = useState();
  // const [updatedFilter, setUpdatedFilter] = useState();
  // const [targetFilter, setTargetFilter] = useState();

  // const [moreFilterOpen, setMoreFilterOpen] = useState(false);

  const [resetAll, setResetAll] = useState(false);
  const [tagData, setTagData] = useState([]);
  const [tagFilter, setTagFilter] = useState();
  const [createdFilter, setCreatedFilter] = useState();
  const [updatedFilter, setUpdatedFilter] = useState();
  const [filterOpenTags, setFilterOpenTags] = useState(false);
  const [filterOpenCreated, setFilterOpenCreated] = useState(false);
  const [filterOpenUpdated, setFilterOpenUpdated] = useState(false);

  const [filters, setFilters] = useState({});
  const [locationFilter, setLocationFilter] = useState({});
  const [updatedTag, setUpdatedTag] = useState({});
  const [direactDealFilter, setDireactDealsFilter] = useState({});

  const [productList, setProductList] = useState([]);

  const screenwidth = useScreenWidth();
  const location = useLocation();
  const navigate = useNavigate();
  const [dropdownPositions, setDropdownPositions] = useState({
    tags: {},
    created: {},
    updated: {},
  });

  const [quickSearchData, setQuickSearchData] = useState(location?.state);
  // Getting Data

  const handleView = (e, item) => {
    e.preventDefault();
    window.open(`/dashboard/company/${item}`, "_blank");
  };

  const fetchTags = async () => {
    const res = await getData({
      endpoint: "Search/getTags",
      params: {
        type: "Manager/Sponsor",
      },
      token: cookies.t,
    });
    let result = [];
    res.forEach((item) => {
      if (item !== null) {
        result.push({ value: item, label: item });
      }
    });
    setTagData(result);
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const handleDirect = (direactDealFilter) => {
    const tempDirect =
      direactDealFilter["Direct Deals"]?.map((item) => item?.value) || [];
    const tempSPV =
      direactDealFilter["Specialty Purpose Vehicle"]?.map(
        (item) => item?.value
      ) || [];

    return [...tempDirect, ...tempSPV];
  };

  useEffect(() => {
    if (quickSearchData?.value) {
      const tempData = {
        "Product Type": [quickSearchData],
      };
      setFilters(tempData);
    }
    if (location.state) {
      navigate("/dashboard/products/productdatabase", {
        replace: true,
        state: null,
      });
    }
  }, [quickSearchData, location.state, navigate]);

  const fetchProductData = async () => {
    try {
      dispatch(setLoading(true));
      const res = await getProductsData({
        token: cookies.t,
        search: searchvalue,
        currentPage,
        itemsPerPage,
        aum: aum,
        // locationData: locationData,
        categoryData: filters?.["Product Category"]?.map(
          (proCategory) => proCategory?.value
        ),
        product_type: filters?.["Product Type"]?.map(
          (productType) => productType?.value
        ),
        product_strategy:
          (direactDealFilter["Direct Deals"] ||
            direactDealFilter["Specialty Purpose Vehicle"]) &&
          filters?.["Product Strategy"]?.length > 0
            ? [
                ...handleDirect(direactDealFilter),
                ...filters["Product Strategy"].map(
                  (category) => category?.value
                ),
              ]
            : direactDealFilter["Direct Deals"] ||
              direactDealFilter["Specialty Purpose Vehicle"]
            ? handleDirect(direactDealFilter)
            : filters?.["Product Strategy"]?.map((category) => category?.value),

        // sectorGeoData: sectorGeoData,
        // productInterest: quickSearchData ? data : productInterest,
        // targetFilter: targetFilter,
        country:
          locationFilter?.Country &&
          locationFilter?.["Country"]?.map((country) => country.label),
        state:
          locationFilter?.State &&
          locationFilter?.["State"]?.map((state) => state.label),
        city:
          locationFilter?.City &&
          locationFilter?.["City"]?.map((city) => city.label),
        sector:
          filters?.Sector && filters?.["Sector"]?.map((sector) => sector.value),
        geography:
          filters?.Geography &&
          filters?.["Geography"]?.map((geography) => geography.value),
        fundingFilter: fundingGoal,
        // tags: tagFilter?.Tags && tagFilter?.["Tags"]?.map((tag) => tag.label),
        tags: tagFilter,
        after_date: updatedTag?.startDate0,
        before_date: updatedTag?.endDate0,
        created_start_date: createdFilter?.created_start_date,
        created_end_date: createdFilter?.created_end_date,
        updated_start_date: updatedFilter?.updated_start_date,
        updated_end_date: updatedFilter?.updated_end_date,
        sort_by: sort_by,
      });
      if (res) {
        setShowLoader(true);
      }
      setProductList(res);
      // dispatch(setLoading(false));
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    if (location.state && !hasMounted.current) {
      hasMounted.current = true; // Mark as mounted
      return;
    }

    // if (refetch) {
    fetchProductData();
    // }
  }, [
    searchvalue,
    currentPage,
    itemsPerPage,
    aum,
    fundingGoal,
    direactDealFilter,
    filters,
    updatedTag,
    locationFilter,
    tagFilter,
    createdFilter,
    updatedFilter,
    sort_by,
    cookies.t,
  ]);
  // Pagination --------------------
  const totalItems = productList?.total_record;

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);
  // ---------------------------------

  // Search --------------------------
  const handleSearch = (e) => {
    setSearchvalue(e);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const handleDebounceSearch = debounce(handleSearch, 1000);

  // -------------------------

  const handleResetAll = () => {
    setAum(() => ({
      min: null,
      max: null,
    }));
    setResetAll(!resetAll);
  };

  const updateDropdownPositions = () => {
    const tagsDropdown = document.getElementById("tagsDropdown");
    const tagsDropdownRect = tagsDropdown?.getBoundingClientRect();

    const createdDropdown = document.getElementById("createdDropdown");
    const createdDropdownRect = createdDropdown?.getBoundingClientRect();

    const updatedDropdown = document.getElementById("updatedDropdown");
    const updatedDropdownnRect = updatedDropdown?.getBoundingClientRect();

    setDropdownPositions({
      tags: {
        left: tagsDropdownRect?.left,
      },
      created: {
        left: createdDropdownRect?.left,
      },
      updated: {
        left: updatedDropdownnRect?.left,
      },
    });
  };

  useEffect(() => {
    updateDropdownPositions();
  }, [screenwidth]);

  const shouldShowSaveAll = () => {
    const hasFilters = filters && Object.keys(filters).length > 0;

    const hasLocationFilter =
      locationFilter && Object.keys(locationFilter).length > 0;

    const hasTagFilter = tagFilter && tagFilter.length > 0;

    const hasCreatedFilter =
      createdFilter &&
      typeof createdFilter === "object" &&
      !Array.isArray(createdFilter) &&
      Object.keys(createdFilter).length > 0;

    const hasUpdatedFilter =
      updatedFilter &&
      typeof updatedFilter === "object" &&
      !Array.isArray(updatedFilter) &&
      Object.keys(updatedFilter).length > 0;

    const hasAum = aum && (aum.min || aum.max);

    const hasDirectDeal =
      direactDealFilter && Object.keys(direactDealFilter).length > 0;

    const hasFundingGoal = fundingGoal && (fundingGoal.min || fundingGoal.max);

    // Final condition check
    const result =
      hasFilters ||
      searchvalue ||
      hasLocationFilter ||
      hasTagFilter ||
      hasCreatedFilter ||
      hasUpdatedFilter ||
      hasAum ||
      hasFundingGoal ||
      hasDirectDeal;

    return result;
  };

  return (
    <div className={style.mainCompanyDiv}>
      <div className="d-flex justify-content-between">
        <div className="h1-i">Companies</div>
        <Search variant="search_md" onSearchEmit={handleDebounceSearch} />
      </div>
      <div className={style.companiesHeader}>
        <div className="d-flex gap-2 flex-wrap mt-3">
          {/* <AumFilter
            handleAum={(data) => {
              setAum(data);
            }}
            reset={resetAll}
          /> */}

          <SortFunctionality
            reset={resetAll}
            handleSort={(sort) => {
              setSort_By(sort);
            }}
          />

          <Funds
            onFilterChange={(values) => {
              setFilters(values);
            }}
            type="product"
            headingtext="Funds"
            headingMobile={false} // Set to true if you want to hide the "Filters" button text on mobile
            disableKeysUntilCondition={true} // Pass the disableKeysUntilCondition object
            reset={resetAll}
            id="more_Filters"
            isDateRange
            dateRangeCount={1}
            dateRangeNames={["AUM($mm)"]}
            handleAum={(data) => {
              setAum(data);
            }}
            QuickSearch={quickSearchData}
            setQuickSearchData={setQuickSearchData}
            // className={style.reset_responsive_Mobile_btn}
          />
          <DireactDeal
            type="product"
            headingtext="Direct Deals"
            id="direact_deals"
            headingMobile={false} // Set to true if you want to hide the "Filters" button text on mobile
            disableKeysUntilCondition={true}
            isDateRange
            dateRangeCount={1}
            dateRangeNames={["Funding Goal($mm)"]}
            onFilterChange={(values) => {
              console.log("direct", values);
              setDireactDealsFilter(values);
            }}
            handleAum={(data) => {
              setFundingGoal(data);
            }}
            reset={resetAll}
          />
          <Location
            onFilterChange={(values) => {
              setLocationFilter(values);
            }}
            type="product"
            headingtext="Location"
            headingMobile={false}
            disableKeysUntilCondition={true}
            reset={resetAll}
            id={"location_filter"}
          />
          <CreatedInvestorFilter
            headerText="Created Date"
            handleFilter={(data) => setCreatedFilter(data)}
            filterOpenCreated={filterOpenCreated}
            setFilterOpenCreated={setFilterOpenCreated}
            id="createdDropdown"
            dropdownPosition={dropdownPositions.created}
            type="small"
            reset={resetAll}
          />
          <UpdateInvestorFilter
            handleFilter={(data) => setUpdatedFilter(data)}
            filterOpenUpdated={filterOpenUpdated}
            setFilterOpenUpdated={setFilterOpenUpdated}
            id="updatedDropdown"
            dropdownPosition={dropdownPositions.updated}
            type="small"
            reset={resetAll}
          />
          {/* <UpdatedTag
            isDateRange
            dateRangeCount={3}
            dateRangeNames={[
              "Target Close Date",
              "Created Date",
              "Updated Date",
            ]}
            filterOpen={moreFilterOpen}
            headingtext="Updated On & Tags"
            setFilterOpen={setMoreFilterOpen}
            reset={resetAll}
            type="product"
            onFilterChange={(filters) => {
              setUpdatedTag(filters);
            }}
          /> */}
          <MultiselectFilter
            title="Tags"
            icon={<ArrowDown />}
            data={tagData}
            handleFilter={(filter) => {
              setTagFilter(filter);
              setResetAll(false);
            }}
            open={filterOpenTags}
            setOpen={setFilterOpenTags}
            setQuickSearchData={setQuickSearchData}
            id="tagsDropdown"
            dropdownPosition={dropdownPositions.tags}
            sizeType="small"
            reset={resetAll}
            tabresponsive
            info={true}
            information={"Based on added Tags"}
          />

          {/* <FundingGoal
            handleFunding={(data) => {
              setFundingGoal(data);
            }}
            reset={resetAll}
          /> */}

          {/* <TargetCloseFilter
            handleFilter={(data) => setTargetFilter(data)}
            filterOpenTarget={filterOpenTarget}
            setFilterOpenTraget={setFilterOpenTraget}
            id="targetDropdown"
            // dropdownPosition={dropdownPositions.target}
            type="small"
            reset={resetAll}
          />
          <CreatedInvestorFilter
            handleFilter={(data) => setCreatedFilter(data)}
            filterOpenCreated={filterOpenCreated}
            setFilterOpenCreated={setFilterOpenCreated}
            id="createdDropdown"
            // dropdownPosition={dropdownPositions.created}
            type="small"
            reset={resetAll}
          />
          <UpdateInvestorFilter
            handleFilter={(data) => setUpdatedFilter(data)}
            filterOpenUpdated={filterOpenUpdated}
            setFilterOpenUpdated={setFilterOpenUpdated}
            id="updatedDropdown"
            // dropdownPosition={dropdownPositions.updated}
            type="small"
            reset={resetAll}
          /> */}

          {/* <FilterComponent
            isDateRange
            dateRangeCount={3}
            dateRangeNames={[
              "Target Close Date",
              "Created Date",
              "Updated Date",
            ]}
            filterOpen={moreFilterOpen}
            setFilterOpen={setMoreFilterOpen}
            reset={resetAll}
            type="product"
            onFilterChange={(filters) => {
              setFilters(filters);
            }}
          /> */}

          <ResetBtn onReset={() => handleResetAll()} type="small" />

          {shouldShowSaveAll() && productList?.data?.length > 0 && (
            <SaveAll
              type={"Company"}
              saveType={"ManagerCompany"}
              filters={filters}
              search={searchvalue}
              setRefetch={setRefetch}
              locationFilter={locationFilter}
              tagFilter={tagFilter}
              createdFilter={createdFilter}
              updatedFilter={updatedFilter}
              aum
              direactDealFilter={handleDirect(direactDealFilter)}
              fundingGoal
            />
          )}
        </div>
      </div>

      {!showLoader ? (
        <>
          {screenwidth > 900 ? (
            <ShimmerDatabase />
          ) : (
            <ContentSharingPhoneShimmer type="Database" />
          )}
        </>
      ) : (
        <>
          <div className={style.investorMapWrap}>
            {productList?.data?.length > 0 ? (
              <>
                <>
                  {productList?.data?.map((item) => {
                    return (
                      <div className={style.investorDataWrap}>
                        <div className={style.investor_inner_datawrap}>
                          <div className={style.investorInfoWrap}>
                            <ImageViewer
                              type="Company"
                              imageUrl={item.profile_photo}
                            />
                            <div>
                              <div
                                className={`d-flex align-items-center gap-3 mb-1 ${style.Product_database_heading_container}`}
                              >
                                <h6
                                  className={style.companyName}
                                  onClick={(e) => {
                                    handleView(e, item?.company_id);
                                  }}
                                >
                                  {item?.name}
                                </h6>
                                <TypeDiv
                                  classes={style.mbres_none}
                                  data={item?.type}
                                  color={
                                    item?.type === "Investor/Manager/Sponsor"
                                      ? "#B5E4CA"
                                      : item?.type === "Investor"
                                      ? "#FFBC99"
                                      : "#CABDFF"
                                  }
                                />
                              </div>
                              {/* <div className={style.companyType}>{item?.type}</div>
                  <div className={style.companyType}>{item?.email}</div> */}
                              {item?.address?.length > 0 && (
                                <div
                                  className={`${style.companyType} ${style.mbres_none}`}
                                >
                                  {item?.address?.[0]?.city +
                                    ", " +
                                    item?.address?.[0]?.state_region +
                                    ", " +
                                    item?.address?.[0]?.country}
                                </div>
                              )}
                              <div
                                className={`${style.companyType} ${style.mbres_none}`}
                              >
                                <div className="d-flex align-items-center">
                                  Added On :{" "}
                                  {moment
                                    .utc(item?.created_date)
                                    .format("MMM D, YYYY")}
                                  <div className="dot"></div>
                                  Last Update:{" "}
                                  {moment
                                    .utc(item?.updated_date)
                                    .format("MMM D, YYYY")}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={style.database_none_wrapper}>
                            <>
                              <TypeDiv
                                data={item?.type}
                                classes={style.typediv_productDatabse}
                                color={
                                  item?.type === "Investor/Manager/Sponsor"
                                    ? "#B5E4CA"
                                    : item?.type === "Investor"
                                    ? "#FFBC99"
                                    : "#CABDFF"
                                }
                              />
                              {item?.address?.length > 0 && (
                                <div className={`${style.companyType}`}>
                                  {item?.address?.[0]?.city +
                                    ", " +
                                    item?.address?.[0]?.state_region +
                                    ", " +
                                    item?.address?.[0]?.country}
                                </div>
                              )}
                              <div className={`${style.companyType}`}>
                                <div className="">
                                  Added On :{" "}
                                  {moment
                                    .utc(item?.created_date)
                                    .format("MMM D, YYYY")}
                                </div>
                              </div>
                              <div className={`${style.companyType}`}>
                                Last Update:{" "}
                                {moment
                                  .utc(item?.updated_date)
                                  .format("MMM D, YYYY")}
                              </div>
                            </>
                          </div>
                        </div>
                        <div className={style.investorButtons}>
                          <SaveComponent
                            type="Company"
                            key={item?.company_id}
                            id={item.company_id}
                            saved_id={item.saved_list}
                            btnType="btn-outline-dark"
                          />

                          <div
                            className="btn btn-outline-dark"
                            onClick={(e) => {
                              handleView(e, item?.company_id);
                            }}
                          >
                            View
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              </>
            ) : (
              <>
                <NoContentCard title="No Data Found" />
              </>
            )}
          </div>

          {productList?.total_record >= 10 && (
            <div className="mt-2 ms-sm-4">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Company;
