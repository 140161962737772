import { Box, Fade } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { getData } from "../../../../services";
import Add from "../../../Icons/Add";
import Heading from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import NoContentCard from "../../../molecules/NoContentCard";
// import FilterComponent from "../../../molecules/filters/FilterComponent";
import { useLocation } from "react-router-dom";
import { useSubscription } from "../../../../SubscriptionContext";
import TabStrip from "../../../molecules/TabStrip";
import AdvancedFilterComponent from "../../../molecules/filters/AdvanceFilterComponent";
import BlogTemplate from "../../../templates/BlogTemplate";
import "./thought.scss";

const ThoughtLeadership = () => {
  const [filters, setFilters] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [categoryData, setCategoryData] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const [activeTab, setActiveTab] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();

  // Get the state from the location
  const { state } = location;

  const { isSubscriptionExpired, handleUserClick } = useSubscription();

  const [blogData, setBlogData] = useState();
  const [cookies] = useCookies(["t", "cid"]);

  useEffect(() => {
    if (state?.tab) {
      setActiveTab(state?.tab);
    } else {
      setActiveTab("Published");
    }
  }, [state]);

  const mappedLibraryCategory = async () => {
    const res = await getData({
      endpoint: "/Blog/getMappedCategory",
      params: { type: "Library" },
      token: cookies.t,
    });
    setCategoryData(res);
  };

  const getBlogData = useCallback(async () => {
    setBlogData(null);
    setIsLoading(true);
    try {
      const res = await getData({
        endpoint: "Blog/getAllCompanyAddedBlogs",
        params: {
          search_query: searchValue,
          // categories: JSON.stringify(categories),
          type: "Library",
          company_id: cookies.cid,
          page: 1,
          page_limit: itemsPerPage,
          created_start_date: filters?.startDate0,
          created_end_date: filters?.endDate0,
          updated_start_date: filters?.startDate1,
          updated_end_date: filters?.endDate1,
          categories:
            filters?.Category && filters?.Category.length > 0
              ? JSON.stringify(filters?.Category)
              : null,
          status:
            activeTab === "Archived" || activeTab === "Rejected"
              ? "Rejected"
              : activeTab === "Published"
              ? "Published"
              : "Pending",
        },
        token: cookies.t,
      });

      if (res) {
        setBlogData(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [searchValue, cookies.t, itemsPerPage, filters, activeTab]);

  useEffect(() => {
    getBlogData();
    mappedLibraryCategory();
  }, [getBlogData]);

  // handle filters

  // useEffect(() => {
  //   console.log('filters')
  // })

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const data = [];
  const navigate = useNavigate();

  const filteredCatData = Array.isArray(categoryData)
    ? categoryData?.map((item) => item.label)
    : [];
  const filterData = {
    Category: filteredCatData ? filteredCatData : [],
  };

  const handleFilterChange = (values) => {
    if (Object.keys(values).length > 0) {
      setFilters(values);
      setIsSearched(true);
    } else {
      setFilters({});
      setIsSearched(false);
    }
  };

  const CustomNoRow = () => {
    return (
      <div className="noContentContainer">
        {Object.keys(filters).length || searchValue ? (
          <NoContentCard
            title="No post Found!"
            containerClass="pt-medium pb-0"
          />
        ) : (
          <NoContentCard
            title="No post added yet!"
            subtitle="Click on Add Post button to create post"
            tag="Add Post"
            handleClick={() => navigate("/dashboard/my-blogs/add")}
            containerClass="pt-medium pb-0"
          />
        )}
      </div>
    );
  };

  const handleSearchEmit = useCallback((value) => {
    setSearchValue(value);
  }, []);

  const handleView = (data) => {
    navigate(`/dashboard/my-blogs/view/${data?.id}`);
  };

  const handleEdit = (data) => {
    navigate(`/dashboard/my-blogs/edit/${data?.id}`);
  };

  const dataArray = useMemo(
    () => [
      {
        id: 1,
        label: "Published",
        view: <></>,
      },
      {
        id: 0,
        label: "Pending",
        view: <></>,
      },
      {
        id: 2,
        label: "Rejected",
        view: (
          <>
            <></>
          </>
        ),
      },
    ],
    [filters, currentPage, itemsPerPage, activeTab]
  );

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };

  const loadMore = () => {
    setItemsPerPage((prev) => prev + 9);
  };

  return (
    <div className="thought-container" id="MyBlogs-Container">
      <div className="d-flex justify-content-between align-items-center">
        <Heading title="My Blogs" type="main" classes="mt-2 mb-0" />

        <div className="d-flex gap-3">
          <Search
            onSearchEmit={(value) => {
              handleSearchEmit(value);
              setIsSearched(true);
            }}
            placeholder="Search by Name, De..."
            variant="search_lg"
          />

          <AdvancedFilterComponent
            filterData={filterData}
            onFilterChange={handleFilterChange}
            isDateRange
            dateRangeCount={2}
            dateRangeNames={["Create Date", "Update Date"]}
            type="right"
          />
          <button
            className="btn btn-primary d-flex gap-2"
            onClick={(e) =>
              // isSubscriptionExpired
              //   ? handleUserClick(e)
              //   :
              // Commented bcz of suggested permission modifications
              navigate("/dashboard/my-blogs/add")
            }
          >
            <Add /> Add Post
          </button>
        </div>
      </div>

      <div>
        {/* <div className="d-flex justify-content-end main-blog-responsive mt-0">
          <div className="d-flex gap-3 filter-blog">
       
          </div>
        </div> */}

        <div className="mt-3">
          <TabStrip
            activeTab={activeTab}
            onTabChange={onTabChange}
            data={dataArray}
          />
        </div>

        {/* <CustomDatagrid
          rows={blogData?.data || []}
          columns={columns}
          getRowId={(row) => row.id}
          height={() => 90}
          CustomNoRowsOverlay={CustomNoRow}
          hoverColor="ffffff"
        /> */}

        <Fade in={true} timeout={1800} key={activeTab}>
          <Box sx={{ width: "100%" }}>
            <Box>
              <BlogTemplate
                data={blogData?.data}
                type="Library"
                isSearched={isSearched}
                blogReq="edit"
                isLoading={isLoading}
              />

              <div className="load-more">
                {blogData?.total_record >= itemsPerPage && (
                  <button
                    className="btn btn-outline-dark btn-sm"
                    onClick={() => loadMore()}
                  >
                    Load More
                  </button>
                )}
              </div>
            </Box>
          </Box>
        </Fade>
      </div>
    </div>
  );
};

export default ThoughtLeadership;
