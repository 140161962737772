import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSubscription } from "../../../SubscriptionContext";
import CloseBtnIcon from "../Modal/CloseBtnIcon";
import "./styles.scss";

const TrialBanner = () => {
  const [type, setType] = useState(null);
  const [bannerVisible, setBannerVisible] = useState(false);
  const [bannerDismissed, setBannerDismissed] = useState(false);
  const { setTop } = useSubscription();
  // const [, setStoredValue] = useLocalStorage("refnavtop");
  const data = localStorage.getItem("trial_info");
  const trial_data = JSON.parse(data);
  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  const upgrade_link = process.env.REACT_APP_PAYMENT_LINK;

  const localMode =
    userInfo?.is_last_subs_mode_trial || trial_data?.is_last_subs_mode_trial;

  useEffect(() => {
    if (trial_data) {
      const { is_trial, subscription_enable, days_left } = trial_data;

      // Show the banner if it's a trial, or if subscription is enabled and less than 15 days are left
      if (is_trial || (subscription_enable && days_left < 15)) {
        // debugger;
        setTop("47px");
        // setStoredValue("47px");
        setType("going-to-end");
        setBannerVisible(true);
      } else if (!subscription_enable) {
        // debugger;
        setTop("47px");
        setBannerVisible(true);
        setType("ended");
      }
    }
  }, []);

  return (
    <>
      {!bannerDismissed && bannerVisible && (
        <div className="trial-banner">
          <div></div>

          {type === "going-to-end" && (
            <div>
              Your {trial_data?.is_trial ? "Trial" : "Subscription"} ends on{" "}
              {moment(trial_data?.subs_end_date).format("MMM D, YYYY")}. &nbsp;
              <a
                style={{ textDecoration: "underline" }}
                href={upgrade_link}
                target="_blank"
                rel="noreferrer"
              >
                Click here
              </a>{" "}
              &nbsp;to {trial_data?.is_trial ? "upgrade" : "renew"} now.
            </div>
          )}
          {type === "ended" && (
            <div>
              {localMode
                ? "Your Free Trial Has Ended."
                : "Your Subscription Has Ended. "}{" "}
              <a
                style={{ textDecoration: "underline" }}
                href={upgrade_link}
                target="_blank"
                rel="noreferrer"
              >
                Click here
              </a>{" "}
              &nbsp;to {localMode ? "upgrade" : "renew"} now.
            </div>
          )}

          <div
            className="trial-icon-div cursor-pointer"
            onClick={() => {
              setBannerDismissed(true);
              setTop("0px");
            }}
          >
            <CloseBtnIcon />
          </div>
        </div>
      )}
    </>
  );
};

export default TrialBanner;
