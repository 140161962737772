import { Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/toast";
import { htmlToTextWithSpaces } from "../../../../helpers/htmlToText";
import useScreenWidth from "../../../../hooks/useScreenwidth";
import { getData, patchFormData, postFormData } from "../../../../services";
import Dropdown from "../../../atoms/Dropdown";
import Heading from "../../../atoms/Heading";
import ImageSelector from "../../../molecules/ImageSelector/ImageSelector";
import TextEditor from "../../../organisms/TextEditor/TextEditor";
import EditBlock from "../../../templates/BlockEdit";
import "./thought.scss";

const Add = ({ type }) => {
  const { id } = useParams();
  const [cookies] = useCookies(["t", "cid", "cuid"]);
  const [data, setData] = useState();
  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.peopleData?.peopleData);
  const companyData = [{ value: cookies.cid, label: userInfo?.company_name }];

  const [categoryData, setCategoryData] = useState([]);
  const [fileData, setFileData] = useState();
  // const [companyData, setCompanyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useRef(true);
  const screenWidth = useScreenWidth();

  const getTextFromFile = async (fileLink) => {
    try {
      const response = await fetch(fileLink);
      if (!response.ok) {
        throw new Error("Failed to fetch file");
      }

      // Extract the text content from the response
      const text = await response.text();

      setFileData(text);

      return text;
    } catch (error) {
      console.error("Error fetching file:", error);
      return null; // Return null if there's an error
    }
  };

  useEffect(() => {
    if (data) {
      getTextFromFile(data?.text);
    }
  }, []);

  const fetchCategoryData = async () => {
    const res = await getData({
      endpoint: "Taxonomy/getCategoryList",
      params: { category: blogType },
      token: cookies.t,
    });
    setCategoryData(res);
  };

  const blogType = "Library";

  const initialValues = {
    cover_picture: data?.cover_photo || null, // You might want to initialize it based on your needs
    thumbnail: data?.thumbnail || null,
    header: data?.header || null,
    category: data?.category || null,
    text: fileData || null,
    company: cookies.cid,
    blogType: blogType || null,
  };

  const ImageSelectorStyle = {
    borderRadius: "10px",
    border: "1px solid #EFEFEF",
    // padding: "10px",
    width: "12rem",
    height: "9rem",
  };

  const fetchData = async () => {
    if (blogType === "Resource") {
      // dispatch(setLoading(true));

      const res = await getData({
        endpoint: "Blog/getResourcesBlogDetails",
        params: { blog_id: id },
        token: cookies.t,
      });

      setData(res);
      getTextFromFile(res?.text);
      // dispatch(setLoading(false));
    } else if (blogType === "Library") {
      // dispatch(setLoading(true));
      const res = await getData({
        endpoint: "Blog/getLibraryDetails",
        params: { blog_id: id },
        token: cookies.t,
      });
      setData(res);
      getTextFromFile(res?.text);
      // dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      fetchCategoryData();
      if (type === "edit") {
        fetchData();
      }
    }
    isMounted.current = false;
  }, []);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    // Handle form submission here

    if (blogType === "Library") {
      try {
        const res = await postFormData({
          endpoint: "Blog/addLibraryBlogs",
          data: {
            ...values,
            sub_header: htmlToTextWithSpaces(values.text).slice(0, 600),
            is_customer_blog: true,
            status: "Pending",
            user_id: cookies.cuid,
          },
          params: {},
          token: cookies.t,
        });
        if (res) {
          toast.success("Post Added Sucessfully", {
            duration: 1000,
            style: confimationStyles,
          });
          setIsLoading(false);
          navigate("/dashboard/my-blogs", { state: { tab: "Pending" } });
        } else {
          toast.error("An error occured", {
            duration: 1000,
            style: errorStyles,
          });
        }
      } catch (error) {
        if (
          // eslint-disable-next-line no-constant-condition
          error.message === "Request failed with status code 413" ||
          "Network Error"
        )
          toast(
            "Blog size limit exceeded. Please ensure that added content does not exceed 20 MB.",
            { duration: 1000, style: errorStyles }
          );
        setIsLoading(false);
      }
    } else {
      try {
        const res = await postFormData({
          endpoint: "Blog/addResourcesBlog",
          data: {
            ...values,
            sub_header: htmlToTextWithSpaces(values.text).slice(0, 600),
            is_customer_blog: true,
          },
          params: {},
          token: cookies.t,
        });
        // console.log('res', res)
        if (res.status) {
          toast.success("Blog Added Sucessfully", {
            duration: 1000,
            style: confimationStyles,
          });
          setIsLoading(false);
          navigate(-1);
        } else {
          toast.error("An error occured", {
            duration: 1000,
            style: errorStyles,
          });
        }
      } catch (error) {
        if (
          // eslint-disable-next-line no-constant-condition
          error.message === "Request failed with status code 413" ||
          "Network Error"
        )
          toast(
            "Blog size limit exceeded. Please ensure that added content does not exceed 20 MB.",
            { duration: 1000, style: errorStyles }
          );
        setIsLoading(false);
      }
    }
  };

  const handleUpdate = async (values) => {
    setIsLoading(true);

    if (blogType === "Library") {
      try {
        const res = await patchFormData({
          endpoint: "Blog/customer/editLibraryBlog",
          data: {
            ...values,
            sub_header: htmlToTextWithSpaces(values.text).slice(0, 600),
            user_id: cookies.cuid,
          },
          params: { blog_id: id },
          token: cookies.t,
        });
        if (res.status) {
          toast.success("Blog Sent for approval.", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate(-1);
        } else {
          toast.error("An error occured", {
            duration: 1000,
            style: errorStyles,
          });
        }
        setIsLoading(false);
      } catch (error) {
        if (
          // eslint-disable-next-line no-constant-condition
          error.message === "Request failed with status code 413" ||
          "Network Error"
        )
          toast(
            "Blog size limit exceeded. Please ensure that added content does not exceed 20 MB.",
            { duration: 1000, style: errorStyles }
          );
        setIsLoading(false);
      }
    } else {
      try {
        const res = await patchFormData({
          endpoint: "Blog/editResourcesBlog",
          data: {
            ...values,
            sub_header: htmlToTextWithSpaces(values.text).slice(0, 600),
          },

          params: { blog_id: id },
          token: cookies.t,
        });
        if (res.status) {
          toast.success("Blog Sent for approval.", {
            duration: 1000,
            style: confimationStyles,
          });
          // navigate(-1);
        } else {
          toast.error("An error occured", {
            duration: 1000,
            style: errorStyles,
          });
        }
      } catch (error) {
        if (
          // eslint-disable-next-line no-constant-condition
          error.message === "Request failed with status code 413" ||
          "Network Error"
        )
          toast(
            "Blog size limit exceeded. Please ensure that added content does not exceed 20 MB.",
            { duration: 1000, style: errorStyles }
          );
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const validationSchema = Yup.object().shape({
    cover_picture: Yup.string().nullable(),
    thumbnail: Yup.string().nullable(),
    header: Yup.string().required("Title is required"),
    category: Yup.string().required("Category is required"),
    text: Yup.string().required("Description is required"),
    company: Yup.string().nullable(),
  });

  return (
    <>
      {screenWidth < 900 ? (
        <EditBlock name="Blogs Add or Edit" />
      ) : (
        <div className="thought-container" id="Thoughts-Form-Container">
          <Heading
            title={type === "edit" ? "Edit Post" : "Add Post"}
            type="main"
            classes="mt-2"
          />

          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
            validationSchema={validationSchema}
          >
            {({ setFieldValue, values, errors, isValid, dirty }) => (
              <>
                <Form>
                  <div>
                    <div className="row">
                      <div className="col-md">
                        <label htmlFor="">Cover Photo</label>
                        <ImageSelector
                          selectedImage={data?.cover_photo}
                          style={ImageSelectorStyle}
                          type="Company"
                          handleFile={(file) => {
                            setFieldValue("cover_picture", file);
                            setFieldValue("remove_cover_photo", false);
                          }}
                          aspect={2}
                          description="Image should be 1400x400 and size is 1MB"
                          onRemove={() =>
                            setFieldValue("remove_cover_photo", true)
                          }
                        />
                      </div>
                      <div className="col-md mb-2">
                        <label htmlFor="">Thumbnail</label>
                        <ImageSelector
                          selectedImage={data?.thumbnail}
                          style={ImageSelectorStyle}
                          type="Company"
                          handleFile={(file) => {
                            setFieldValue("thumbnail", file);
                            setFieldValue("remove_thumbnail", false);
                          }}
                          aspect={2}
                          description="Image should be 600X400 and size is 1MB"
                          onRemove={() =>
                            setFieldValue("remove_thumbnail", true)
                          }
                        />
                      </div>
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="">Title</label>
                          <Field
                            type="text"
                            placeholder="Enter Title"
                            name="header"
                            id="header"
                          />
                        </div>
                        <div className="form-group mt-3">
                          <div className="row g-3">
                            <div className="col-md">
                              <label htmlFor="">Category</label>
                              <Dropdown
                                data={categoryData}
                                onChange={(option) =>
                                  setFieldValue("category", option)
                                }
                                placeholder="Select Category"
                                selectedValue={data?.category}
                              />
                            </div>

                            <div className="col-md">
                              <label htmlFor="">Company Name</label>
                              <Dropdown
                                data={companyData}
                                onChange={(option) =>
                                  setFieldValue("company", option)
                                }
                                placeholder="Select Company"
                                selectedValue={cookies.cid}
                                isDisabled={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <TextEditor
                        label="Text"
                        name="text"
                        classes={"richTextEditor"}
                        onChange={(data) => setFieldValue("text", data)}
                        value={fileData}
                        editorClases="fixed-position-styles"
                      />
                    </div>
                  </div>
                </Form>

                <footer className="footer">
                  <div className="d-flex gap-3">
                    <button
                      type="submit"
                      className={`btn btn-outline-dark px-4 mt-0 `}
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {type === "edit" ? (
                      <button
                        type="submit"
                        className="btn btn-primary mt-0"
                        onClick={() => handleUpdate(values)}
                        disabled={!isValid || isLoading || !dirty}
                      >
                        Update
                        {isLoading && (
                          <span
                            className="spinner-border spinner-border-sm ms-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary mt-0"
                        onClick={() => handleSubmit(values)}
                        disabled={!isValid || !dirty || isLoading}
                      >
                        Create
                        {isLoading && (
                          <span
                            className="spinner-border spinner-border-sm ms-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </button>
                    )}
                  </div>
                </footer>
              </>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default Add;
